<template>
  <page-layout :show-back-button="false">
    <span slot="header-name">{{ $tfo('general,,3 | preferences') }}</span>
    <el-form
      slot="content"
      :size="$vars.sizes.form"
      :label-position="$vars.forms.label_position"
      :labelWidth="$vars.forms.label_width"
      v-loading="state.loading"
      :name="$options.name"
    >
      <el-form-item :key="`objects-${objectsType}`" :label="$tfo(`${objectsType}_confidence_threshold`)" v-for="objectsType in $store.getters.enabledObjects">
        <confidence-select-proxy :objects="objectsType" v-model="state.items[getConfidenceFieldName(objectsType)]" />
      </el-form-item>

      <el-form-item :label="$tf('thumbnail_jpeg_quality')">
        <el-slider ref="thumbnailSlider" name="thumbnail-quality" v-model="state.items.thumbnail_jpeg_quality" show-input :min="50" :max="95" :step="1" />
      </el-form-item>

      <el-form-item :label="$tfo('events_cleanup')">
        <el-checkbox v-model="cleanup_enabled">{{ $tf('enabled') }}</el-checkbox>
      </el-form-item>

      <div v-if="cleanup_enabled">
        <template v-for="objectsType in $store.getters.enabledObjects">
          <el-form-item :key="objectsType" :label="$tf(`${objectsType}_events_cleanup_matched`)">
            <events-max-age-input v-model="state.items[`${getObjectsTypeSingleForm(objectsType)}_events_max_matched_age`]" :objects-type="objectsType" />
          </el-form-item>
          <el-form-item :key="objectsType" :label="$tf(`${objectsType}_events_cleanup_unmatched`)">
            <events-max-age-input v-model="state.items[`${getObjectsTypeSingleForm(objectsType)}_events_max_unmatched_age`]" :objects-type="objectsType" />
          </el-form-item>
          <el-form-item :key="objectsType" :label="$tf(`${objectsType}_events_fullframe_cleanup_matched`)">
            <events-max-age-input
              v-model="state.items[`${getObjectsTypeSingleForm(objectsType)}_events_max_fullframe_matched_age`]"
              :objects-type="objectsType"
            />
          </el-form-item>
          <el-form-item :key="objectsType" :label="$tf(`${objectsType}_events_fullframe_cleanup_unmatched`)">
            <events-max-age-input
              v-model="state.items[`${getObjectsTypeSingleForm(objectsType)}_events_max_fullframe_unmatched_age`]"
              :objects-type="objectsType"
            />
          </el-form-item>
        </template>
      </div>

      <el-form-item label="Build version">
        <span>{{ $store.state.config.build.version }}</span>
      </el-form-item>
      <el-form-item label="Build tag">
        <span>{{ $store.state.config.build.tag }}</span>
      </el-form-item>
      <el-form-item label="Build date">
        <span>{{ $store.state.config.build.date | isoStringToDate | formatDateTime }}</span>
      </el-form-item>
      <el-form-item>
        <el-button name="save-btn" size="large" type="primary" @click="update">{{ $tf('common.update') }}</el-button>
      </el-form-item>
    </el-form>
  </page-layout>
</template>
<script>
import { ObjectsType, ObjectsTypeSingleForm } from '@/store/objects/get.module';
import ConfidenceSelect from '../common/confidence.select';
import ConfidenceSelectProxy from '../common/confidence.select.proxy';
import EventsMaxAgeInput from './events-max-age-input';
import PageLayout from '@/components/page/layout';

export default {
  name: 'page-settings',
  components: { ConfidenceSelectProxy, ConfidenceSelect, EventsMaxAgeInput, PageLayout },
  data: function () {
    return {
      ObjectsType,
      cleanup_explicitly_enabled: false,
      threshold: 0.75,
      faces: [],
      displayResults: false,
      $sliders: []
    };
  },
  computed: {
    allConfidenceThresholdProps() {
      const objects = this.$store.getters.enabledObjects.map((v) => ObjectsTypeSingleForm[v]);
      return objects.map((v) => `${v}_confidence_threshold`);
    },
    allCleanupProps() {
      const types = ['events_max_matched_age', 'events_max_unmatched_age', 'events_max_fullframe_matched_age', 'events_max_fullframe_unmatched_age'],
        objects = this.$store.getters.enabledObjects.map((v) => ObjectsTypeSingleForm[v]);
      return objects.reduce((m, v) => [...m, ...types.map((t) => `${v}_${t}`)], []);
    },
    hasFacesConfidence() {
      return this.$store.getters.hasFacesConfidence;
    },
    state() {
      return this.$store.state.settings;
    },
    cleanup_enabled: {
      get() {
        return !!this.allCleanupProps.find((v) => this.state.items[v] > 0);
      },
      set(v) {
        if (v) {
          this.allCleanupProps.forEach((v) => {
            this.state.items[v] = this.$store.state.config.event.max_age.default;
          });
        } else {
          this.allCleanupProps.forEach((v) => {
            this.state.items[v] = 0;
          });
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch('get_settings');
    this.listenSliders(['confidenceSlider', 'episodeSlider', 'thumbnailSlider']);
  },
  beforeDestroy() {
    this.unlistenSliders();
  },
  methods: {
    getObjectsTypeSingleForm(v) {
      return ObjectsTypeSingleForm[v];
    },
    getConfidenceFieldName(v) {
      return ObjectsTypeSingleForm[v] + '_confidence_threshold';
    },
    applyQueryFilter(v) {},
    unlistenSliders() {
      window.removeEventListener('mouseup', this.cleanTooltipHandler);
      this.$sliders = null;
    },
    listenSliders(v) {
      this.$sliders = v;
      window.addEventListener('mouseup', this.cleanTooltipHandler);
    },
    cleanTooltipHandler() {
      this.$sliders.forEach((v) => {
        const el = this.$refs[v],
          btn = el && el.$refs ? el.$refs['button1'] : null;
        setTimeout(() => {
          btn && btn.hideTooltip();
        }, 100);
      });
    },
    update() {
      const props = ['thumbnail_jpeg_quality', ...this.allConfidenceThresholdProps, ...this.allCleanupProps],
        updateItem = props.reduce((m, v) => {
          m[v] = this.state.items[v];
          return m;
        }, {});

      this.$store
        .dispatch('update_settings', updateItem)
        .then((settings) => this.$store.dispatch('setDefaultConfidenceThreshold', settings))
        .then((v) => {
          this.$notify({ type: 'success', message: this.$tf(['common.action', 'common.success']) });
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
        });
    }
  }
};
</script>
